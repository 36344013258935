import React from 'react';
import {BrowserRouter} from "react-router-dom";
import GlobalStyles from "./styles/GlobalStyles";
import RoutesHoupa from "./routes";

function App() {
  return (
    <BrowserRouter>
      <GlobalStyles />
      <RoutesHoupa />
    </BrowserRouter>
  );
}

export default App;
