import styled from "styled-components";

export const Content = styled.main`
  min-height: calc(100vh - 279px);

  @media (max-width: 1279px) {
    min-height: calc(100vh - 307px);

  }

  @media (max-width: 607px) {
    min-height: calc(100vh - 401px);
  }
`
