import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Home from "pages/Home";


const RoutesHoupa: React.FC = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Routes>
            <Route path="/" element={<Home />} />
        </Routes>
    );
};

export default RoutesHoupa;
