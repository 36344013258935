import React  from 'react';
import {Container, Title, SubTitle} from "./styles";


const SubHeader = () => {

  return (
      <Container>
          <Title>houpa! a rede que conecta todos os estilos.</Title>
          <SubTitle>
              Queremos inovar o jeito de comprar e vender roupas levando
              a moda de todos para todos.
          </SubTitle>
      </Container>
  );
};

export default SubHeader;
