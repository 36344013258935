import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    max-height: 100vh;
    max-width: 100vw;

    width: 100%;
    height: 100%;
  }
  
  *, button, input{
    border: 0;
    background: 0;
    font-family: var(--poppins)
  }
  
  button:focus {
    outline: none;
  }

  h1,
  h2,
  h3,
  h4,
  p {
    margin: 0;
  }

  ul,
  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  img {
    display: block;
    max-width: 100%;
  }

  :root {
    --container: 1280px;

    --poppins: Poppins;
    --roboto: 'Roboto';


    --purple: #7F1CEF;
    --purple-hover: #861EFC;
    --purple-medium: #5E0DB3;
    --purple-business: #3D0E73;
    --purple-business-dark: #3F0B6D;

    --white: #FFF;
    
    --grey: #707070;

    --houpa-purple-hover: #7219D6;
    
    @media(max-width:  1280px){
      --container: 608px;
    }

    @media(max-width: 607px){
      --container: 312px;
    }
  }

`;
