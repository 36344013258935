import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0;
  color: var(--grey);

  @media (max-width: 1279px) {
    padding: 47px 0;
  }

  @media (max-width: 607px) {
    padding: 26px calc((100vw - var(--container)) / 2);
  }
`

export const Title = styled.h1`
  font-size: 32px;
  text-align: center;
  line-height: 48px;
  margin-bottom: 12px;

  @media (max-width: 1279px) {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 24px;
  }

  @media (max-width: 607px) {
    margin-bottom: 12px;
  }
`

export const SubTitle = styled.h2`
  font-size: 24px;
  text-align: center;
  line-height: 36px;
  font-weight: 400;
  max-width: 775px;

  @media (max-width: 1279px) {
    font-size: 16px;
    line-height: 24px;

    max-width: 622px;
  }

  @media (max-width: 607px) {
    margin-bottom: 14px;
    line-height: 21px;
  }
`