import styled from "styled-components";

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  
  width: 100%;
  height: 120px;
  
  background-color: var(--purple);
  
  @media(max-width: 1279px){
    height: 100px;
  }
`