import React, {useEffect, useState} from 'react';
import {ButtonLink, Container, ImageText, ImageTitle, PanelImage} from "./styles";
import ForYou from "../../../../assets/for-you.jpg";
import ForBusinesses from "../../../../assets/for-businesses.jpg";
import {getIOS, mobileCheck} from "../../../../helpers/isMobile";


const Mosaic: React.FC = () => {
    const [url, setUrl] = useState('https://negocios.houpa.app/login');

    useEffect(() => {
        if (mobileCheck()) {
            setUrl(
                getIOS() ?
                    'https://apps.apple.com/br/app/houpa-neg%C3%B3cios/id1489513685' :
                    'https://play.google.com/store/apps/details?id=com.houpabusiness.org&hl=pt_BR&gl=US'
            )
        }

    }, []);

    return (
        <Container>
            <PanelImage right image={ForYou}>

                <div>
                    <ImageTitle>Para <strong>você</strong></ImageTitle>

                    <ImageText>
                        Visite a nossa vitrine e<br/>
                        descubra seu próximo<br/>
                        look no houpa!
                    </ImageText>

                    <ButtonLink href={'https://b2c.houpa.app'}>
                        Comprar
                    </ButtonLink>
                </div>

            </PanelImage>
            <PanelImage image={ForBusinesses}>
                <div>
                    <ImageTitle>Para <strong>empresa</strong></ImageTitle>

                    <ImageText>
                        Conecte-se para descobrir<br/>
                        um novo jeito de comprar<br/>
                        e vender moda no atacado.
                    </ImageText>

                    <ButtonLink href={url}>
                        Entrar ou criar conta
                    </ButtonLink>
                </div>
            </PanelImage>
        </Container>
    );
};

export default Mosaic;
