import styled from "styled-components";

export const Container = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 52px 0 32px;

  * {
    font-family: var(--roboto), var(--poppins), Arial, sans-serif;
  }

  @media (max-width: 1279px) {
    padding: 32px 0;
  }

  @media (max-width: 607px) {
    padding: 16px 0 24px;
  }
`

export const Content = styled.div`
  width: var(--container);

  display: flex;
  justify-content: space-between;

  > div {
    flex: 1;
  }

  @media (max-width: 1279px) {
    flex-direction: column-reverse;
  }
`

export const AddressLink = styled.a`
  text-decoration: none;
  color: var(--purple);
  line-height: 19px;

  @media (max-width: 1279px) {
    font-size: 14px;
    line-height: 16px;
  }

  @media (max-width: 607px) {
    font-size: 12px;
  }
`

export const AddressArea = styled.div`
  max-width: 575px;
  margin-right: 66.93px;

  @media (max-width: 1279px) {
    margin-top: 32px;
    margin-right: 0;
  }
`

export const SocialContainer = styled.div`
  display: flex;

  .mr-67 {
    margin-right: 67px;
  }

  @media (max-width: 607px) {
    flex-direction: column;
    
    .mr-67 {
      margin-right: 0;
      margin-bottom: 32px;
    }
  }
`

export const Title = styled.h4`
  color: var(--purple);
  font-size: 16px;

  margin-bottom: 20px;

  @media (max-width: 1279px) {
    font-size: 14px;
  }

  @media (max-width: 607px) {
    font-size: 12px;
  }
`

export const Link = styled.a`
  text-decoration: none;

  &.margin-horizontal {
    margin: 0 12px;
  }

  &.mr {
    margin-right: 16px;
  }
`

export const DFlex = styled.div`
  display: flex;
`
