import React from 'react';
import {AddressArea, AddressLink, Container, Content, DFlex, Link, SocialContainer, Title} from "./styles";
import {ReactComponent as AppleStore} from "assets/app-store.svg";
import {ReactComponent as GooglePlay} from "assets/google-play.svg";
import {ReactComponent as Insta} from "assets/logo-insta.svg";
import {ReactComponent as Face} from "assets/logo-facebook.svg";
import {ReactComponent as Linkedin} from "assets/logo-linkedin.svg";

const FooterHome: React.FC = () => {
    return (
        <Container>
            <Content>
                <AddressArea>
                    <AddressLink href="https://goo.gl/maps/55Z77ZQomxjShQwB6" target="_blank">
                        Rua Professor Cesare Lombroso, 305 - Cj 6 - 3º andar, Bom Retiro - São Paulo/SP <br/>
                        Tel.: (11) 9 9129-9223
                    </AddressLink>
                </AddressArea>
                <SocialContainer>
                    <div className={'mr-67'}>
                        <Title>Compre e venda pelo app do houpa! negócios</Title>

                        <Link
                            href="https://apps.apple.com/br/app/houpa-neg%C3%B3cios/id1489513685"
                            className="mr"
                            target="_blank"
                        >
                            <AppleStore/>
                        </Link>

                        <Link
                            href="https://play.google.com/store/apps/details?id=com.houpabusiness.org&hl=pt_BR&gl=US"
                            target="_blank"
                        >
                            <GooglePlay/>
                        </Link>
                    </div>

                    <div>
                        <Title>Acompanhe nossas redes sociais</Title>

                        <DFlex>

                            <Link href="https://www.instagram.com/houpa.negocios/?hl=pt-br" target="_blank">
                                <Insta/>
                            </Link>

                            <Link className={'margin-horizontal'} href="https://www.facebook.com/houpa.negocios" target="_blank">
                                <Face/>
                            </Link>
                            <Link href="https://www.linkedin.com/company/houpa/?originalSubdomain=br" target="_blank">
                                <Linkedin/>
                            </Link>


                        </DFlex>
                    </div>

                </SocialContainer>
            </Content>
        </Container>
    );
};

export default FooterHome;
