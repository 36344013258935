import styled from "styled-components";

interface Panel {
    image: string
    right?: boolean
}

export const Container = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 607px) {
    flex-direction: column;
  }
`

export const PanelImage = styled.div<Panel>`
  display: flex;
  justify-content: flex-end;
  align-items: ${({right}) => right ? 'flex-end' : 'flex-start'};
  flex-direction: column;

  width: 50%;
  height: 650px;
  padding: 45px 116px;

  background-image: url(${props => props.image});
  background-size: cover;

  text-align: ${({right}) => right ? 'right' : 'normal'};
  color: var(--white);

  @media (max-width: 1550px) {
    height: 487px;
  }

  @media (max-width: 1279px) {
    padding: 50px 32px;
  }

  @media (max-width: 607px) {
    padding: 83px 24px;
    width: 100%;
  }
`

export const ImageTitle = styled.h1`
  font-size: 32px;
  line-height: 48px;
  font-weight: 400;
  margin-bottom: 8px;

  @media (max-width: 1279px) {
    font-size: 24px;
    line-height: 36px;
  }

  @media (max-width: 607px) {
    font-size: 20px;
    line-height: 30px;
  }
`

export const ImageText = styled.p`
  font-size: 20px;
  line-height: 25px;

  @media (max-width: 1279px) {
    font-size: 16px;
    line-height: 20px;
  }

  @media (max-width: 607px) {
    font-size: 14px;
    line-height: 20px;
  }
`


export const ButtonLink = styled.a`
  display: flex;
  justify-content: center;

  width: 240px;
  padding: 13px 0;
  margin-top: 24px;

  background-color: var(--purple);
  color: var(--white);
  border: none;
  border-radius: 4px;

  font-size: 1.10rem;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: var(--purple-hover);
  }

  @media (max-width: 1279px) {
    width: 210px;
    font-weight: 500;
  }

  @media (max-width: 607px) {
    font-size: 16px;
  }
`