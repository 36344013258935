import React  from 'react';
import {ReactComponent as Logo} from "assets/logo.svg";
import { Header } from './styles';


const HeaderHome:React.FC  = () => {
  return (
      <Header>
            <Logo />
      </Header>
  );
};

export default HeaderHome;
