import React from 'react';
import {Content} from "./styles";
import HeaderHome from "../../components/HeaderHome";
import FooterHome from "../../components/FooterHome";
import SubHeader from './components/SubHeader';
import Mosaic from "./components/Mosaic";

const Home: React.FC = () => {
    return (
        <div>
            <HeaderHome/>

            <Content>
                <SubHeader/>
                <Mosaic/>
            </Content>

            <FooterHome/>
        </div>
    );
};

export default Home;
